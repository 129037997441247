import Link from "next/link"
import OpenInNew from "@/common/icons/open-in-new"
import { useCopy } from "@/lib/contexts/appContext"
import styles from "./top.module.css"
import classNames from "classnames"

export default function MenuTop({ hasMounted, isNarrow, inPrimary }) {
  const location = "top"
  const copy = useCopy()
  const { organization, english } = copy.header ?? {}

  if (hasMounted && isNarrow) {
    return null
  }

  return (
    <ul
      className={classNames(styles.menu, { "in-primary": inPrimary })}
      role="menu"
      data-location={location + "-menu"}>
      <li>
        <Link href="https://www.naturskyddsforeningen.se" prefetch={false}>
          Till naturskyddsforeningen.se
          <OpenInNew className="open-in-new" />
        </Link>
      </li>
      {organization?.url && (
        <li>
          <Link href={organization.url} prefetch={false}>
            {organization?.label ?? "Lokalföreningar"}
          </Link>
        </li>
      )}
      {english?.url && (
        <li>
          <Link href={english.url} prefetch={false}>
            {english?.label ?? "In English"}
          </Link>
        </li>
      )}
    </ul>
  )
}
