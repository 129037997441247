import Link from "next/link"
import dynamic from "next/dynamic"
import { useCopy } from "@/lib/contexts/appContext"
import { NinetyAccount } from "@/components/common/logos/NinetyAccount"
import { SiteLogo } from "@/common/logos/siteLogo"

import Menu from "./menu"
import styles from "./footer.module.css"
import classNames from "classnames"

export default function Footer() {
  const copy = useCopy()
  const { columns, cookies, publisher, menu, socials, year } = copy.footer ?? {}
  const sectionSum = [
    columns?.length,
    socials?.links ? 1 : 0,
    menu ? 1 : 0
  ].reduce((acc, cur) => acc + cur, 0)
  const sectionItems = isNaN(sectionSum) ? 0 : sectionSum

  return (
    <footer className={classNames(styles.footer)}>
      <div className="inner-wrapper">
        <div className="logos">
          <SiteLogo />
          <NinetyAccount className="ninety" />
        </div>
        {sectionItems > 0 && (
          <div className={classNames("section", "columns-" + sectionItems)}>
            {columns &&
              columns.map((column, index) => (
                <Column key={index} column={column} />
              ))}
            <Menu heading={menu} />
            {socials?.links && <Social {...{ socials }} />}
          </div>
        )}
        <div className="bottom-row">
          <span className="publisher">
            &copy; {year + " "}
            {publisher && (
              <Link
                href={publisher?.url}
                target={publisher?.target}
                prefetch={false}>
                {publisher?.title}
              </Link>
            )}
          </span>
          {cookies && (
            <Link href={cookies?.url} target={cookies?.target} prefetch={false}>
              {cookies?.title}
            </Link>
          )}
        </div>
      </div>
    </footer>
  )
}

const Column = ({ column }) => {
  return (
    <div className="column">
      <h4>{column?.heading}</h4>
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: column?.content }}
      />
    </div>
  )
}

const Social = ({ socials }) => {
  const { heading, links } = socials
  return (
    <div className="column socials">
      {heading && <h4>{heading}</h4>}
      <ul>
        {links.map((link) => {
          return (
            <li className={link.service} key={link.url}>
              <Link
                href={link.url}
                prefetch={false}
                rel="noreferrer noopener"
                target="_blank">
                <Icon service={link.service} />
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const Icon = ({ service, ...props }) => {
  const Icon = icons[service] ?? null
  return <Icon {...props} />
}

const icons = {
  facebook: dynamic(() => import("@/components/common/icons/facebook")),
  instagram: dynamic(() => import("@/components/common/icons/instagram")),
  linkedin: dynamic(() => import("@/components/common/icons/linkedin")),
  tiktok: dynamic(() => import("@/components/common/icons/tiktok")),
  x: dynamic(() => import("@/components/common/icons/x")),
  youtube: dynamic(() => import("@/components/common/icons/youtube"))
}
